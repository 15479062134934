import { withSuperJSONPage as _withSuperJSONPage } from "babel-plugin-superjson-next/tools";
import { withSuperJSONProps as _withSuperJSONProps } from "babel-plugin-superjson-next/tools";
import type { GetServerSidePropsContext } from 'next';
import { getServerSession } from 'next-auth/next';
import { getProviders, signIn } from 'next-auth/react';
import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';
import GoogleIcon from '@/client/assets/icons/google.svg';
import { Button } from '@/client/components';
import type { SigninError } from '@/client/containers/views/Auth/containers/EmailSignIn';
import { SignInErrorCard } from '@/client/containers/views/Auth/containers/EmailSignIn'; //EmailSignIn
import { AuthLayout, Logo } from '@/common/components';
import { GithubIcon } from '@/common/components/icons/github';
import { authOptions } from '@/server/auth';
function SignIn() {
  const router = useRouter();
  const {
    error
  } = (router.query as {
    error: SigninError;
  });
  return <AuthLayout>
      <Head>
        <title>Access Your Workspace / Klu.ai</title>
        <meta name="description" content="Klu is an LLM App Platform to design, deploy, and optimize generative apps." />
      </Head>
      <div className="flex flex-col items-center">
        <div className="w-full">
          <Link href="/" aria-label="Home">
            <div className="mx-auto mb-6 flex h-16 w-16 items-center justify-center rounded-full bg-black dark:bg-white px-4 py-4">
              <Logo className="h-12 w-auto fill-white dark:fill-black" />
            </div>
          </Link>
          <h2 className="text-2xl font-semibold font-everett text-black dark:text-white">
            Access your workspace
          </h2>
          <span className="inline-block pt-4 w-4/5 text-center font-everett text-sm text-grey-600 dark:text-zinc-600 dark:text-zinc-400">
            Klu is the LLM App Platform for building, evaluating, and optimizing generative apps.
          </span>
          {error && <SignInErrorCard error={error} />}
        </div>
      </div>

      <div className="mt-6">
        <div className="flex flex-col space-y-2">
          <Button onClick={() => void signIn('google', {
          callbackUrl: undefined
        })} className="w-full py-6" variant={'outline'}>
            <GoogleIcon className="h-6 w-6" />
            <span className="ml-4 font-light text-black dark:text-white">Continue with Google</span>
          </Button>
          <Button onClick={() => void signIn('github', {
          callbackUrl: undefined
        })} className="w-full py-6" variant={'outline'}>
            <GithubIcon className="h-6 w-6" />
            <span className="ml-4 font-light text-black dark:text-white">Continue with Github</span>
          </Button>
        </div>
      </div>
      {/* <div className="mt-6 relative hidden">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-grey-200 dark:border-zinc-800" />
        </div>
        <div className="relative flex justify-center text-xs leading-6">
          <span className="bg-white px-6 text-grey-600 dark:text-zinc-600">Or</span>
        </div>
       </div>
       <EmailSignIn /> */}
      <div className="mt-6 flex flex-row items-center justify-between gap-x-6 border-t border-grey-200 dark:border-zinc-700 pt-6">
        <span className="font-everett text-sm text-black dark:text-white">
          <Link href="/signup">Create an account</Link>
        </span>
        <span className="font-everett text-sm text-black dark:text-white hidden">
          <Link href="/account/reset-password">Reset password</Link>
        </span>
      </div>
    </AuthLayout>;
}
export const getServerSideProps = _withSuperJSONProps(async function getServerSideProps(context: GetServerSidePropsContext) {
  const session = await getServerSession(context.req, context.res, authOptions);
  const {
    callbackUrl
  } = (context.query as {
    callbackUrl?: string;
  });

  // no zhCN in the house
  const locale = context.req.headers['accept-language'];
  if (locale && locale.includes('zh-CN')) {
    return {
      redirect: {
        destination: 'https://klu.ai/',
        permanent: false
      }
    };
  }

  // If the user is already logged in, redirect.
  // Note: Make sure not to redirect to the same page
  // To avoid an infinite loop!
  if (session) {
    if (callbackUrl) {
      return {
        redirect: {
          destination: callbackUrl,
          permanent: false
        }
      };
    }
    return {
      redirect: {
        destination: '/'
      }
    };
  }
  const providers = await getProviders();
  return {
    props: {
      providers: providers ?? []
    }
  };
}, []);
export default _withSuperJSONPage(SignIn);